import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';

export default function HeaderStyle2({ logoSrc, variant, btnText, btnUrl }) {
  const [isSticky, setIsSticky] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleCloseMenu = () => {
    setMobileToggle(false);
  };

  return (
    <>
      <header
        className={`cs_site_header cs_style1 cs_sticky_header ${
          mobileToggle ? 'cs_mobile_toggle_active' : ''
        } ${variant} ${isSticky ? 'cs_active_sticky' : ''}`}
      >
        <div className="cs_main_header">
          <div className="container">
            <div className="cs_main_header_in">
              <div className="cs_main_header_left">
                <Link className="cs_site_branding" to="/">
                  <img src={logoSrc} alt="Logo" />
                </Link>
              </div>
              <div className="cs_main_header_center">
                <nav className="cs_nav">
                  <ul
                    className={`${
                      mobileToggle ? 'cs_nav_list cs_active' : 'cs_nav_list'
                    }`}
                  >
                    <li>
                      <Link to="/" onClick={handleCloseMenu}>
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/about" onClick={handleCloseMenu}>
                        About
                      </Link>
                    </li>
                    <li>
                      <Link to="/services" onClick={handleCloseMenu}>
                        Services
                      </Link>
                    </li>
                    <li>
                      <Link to="/faq" onClick={handleCloseMenu}>
                        FAQ
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact" onClick={handleCloseMenu}>
                        Contact
                      </Link>
                    </li>
                  </ul>
                  {/* Hamburger Icon */}
                  <span
                    className={`cs_menu_toggle ${
                      mobileToggle ? 'cs_toggle_active' : ''
                    }`}
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <span></span>
                  </span>
                  
                </nav>
              </div>
              <div className="cs_main_header_right">
                <Button btnUrl={btnUrl} btnText={btnText} />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
