import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Section from '../Section';
import AboutSectionStyle2 from '../Section/AboutSection/AboutSectionStyle2';
import AboutSectionStyle6 from '../Section/AboutSection/AboutSectionStyle6';
import { pageTitle } from '../../helpers/PageTitle';
import BannerSectionStyle5 from '../Section/BannerSection/BannerSectionStyle5';

export default function Services() {
    pageTitle('Services');
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            console.log('Hash found:', location.hash); // Log the hash
            setTimeout(() => {
                const element = document.querySelector(location.hash);
                if (element) {
                    console.log('Element found:', element); // Log the element
                    element.scrollIntoView({ behavior: 'smooth' });
                } else {
                    console.error('Element not found for hash:', location.hash);
                }
            }, 300);
        }
    }, [location]);

    return (
        <>
            <BannerSectionStyle5
                bgUrl="/images/departments/banner_bg.png"
                imgUrl="/images/services/child-with-toy-car-and-adult-woman.jpg"
                title="Our <br />Services"
                subTitle="These are some of the main services we provide. <br /><br /> Is there a specific service you are looking for that is not listed?<br /> Give us a call at 845-320-2855"
            />
            <Section
                id="eibi"
                topMd={175}
                topLg={75}
                topXl={45}
                bottomMd={100}
                bottomLg={55}
            >
                <AboutSectionStyle6
                    title="Early Intensive Behavioral Intervention (EIBI)"
                    subTitle={[
                        "At StarBright, we offer Early Intensive Behavioral Intervention (EIBI) specially designed for young children ages 18 months to 6 years diagnosed with Autism Spectrum Disorder (ASD). Our EIBI program provides individualized support through one-on-one teaching, play-based learning, and an enriched environment where children can grow alongside their peers. We recommend 20-40 hours per week to help your child build essential skills, which are held in our fun and engaging full-day clinic.",
                    ]}
                    imgUrl="/images/services/girl-on-the-lesson-of-the-development-of-fine-motor-skills2.jpg"
                    altText={"Educator helping child with fine motor skills"}
                />
            </Section>
            <Section
                id="social-skills"
                topMd={175}
                topLg={75}
                topXl={45}
                bottomMd={100}
                bottomLg={55}
            >
                <AboutSectionStyle2
                    title="Social Skills Groups"
                    subTitle="Our social skills groups are designed to help children of all abilities develop important social interactions in a fun and supportive environment. By creating situations that encourage natural learning, we break down complex social skills into simple, easy-to-learn steps. This allows your child to practice and build confidence in making friends, communicating, and working together with others, all while having fun!"
                    imgUrl="/images/services/social-skills-group.jpg"
                    altText={"Educator engaging kids in social emotional learning at circle time"}
                />
            </Section>
            <Section
                id="focused-programs"
                topMd={175}
                topLg={75}
                topXl={45}
                bottomMd={100}
                bottomLg={55}
            >
                <AboutSectionStyle6
                    title="Focused Programs"
                    subTitle={[
                        "Individualized programs are developed for each child to target specific skills needed to learn and grow in both educational and natural settings and may include a smaller amount of recommended hours or different settings to learn and practice skills.",
                        "We create personalized programs for each child, focusing on the unique skills they need to thrive both in school and in everyday life. These programs can be tailored to include fewer recommended hours or simulate different real-life settings to help your child practice and master new skills in a way that feels natural and enjoyable.",
                    ]}
                    imgUrl="/images/services/teacher-an-child-play-kitchen.jpg"
                    altText={"Teacher and child engaged in pretend kitchen play"}
                />
            </Section>
        </>
    );
}
