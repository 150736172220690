import React from 'react';
import BannerSectionStyle5 from '../Section/BannerSection/BannerSectionStyle5';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';

export default function PrivacyPolicy() {
  pageTitle('Privacy Policy');
  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/departments/banner_bg.svg"
        imgUrl="/images/privacy-icons-on-blocks.jpg"
        title="Privacy Policy"
        subTitle="Your privacy is important to us."
      />
    
      <Section
        topMd={200}
        topLg={150}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
      
        <div className="container">
            <h5>
            Effective Date: 12-05-2024
            </h5>
            <p>
            StarBright ABA, PLLC ("we," "us," or "our") is committed to protecting the privacy and security of visitors to our website and the information you provide. This Privacy Policy outlines how we collect, use, and protect your information when you visit www.starbrightaba.com.
            </p>
            <h3>
              1. Scope of This Privacy Policy
            </h3>
              <p>
              This Privacy Policy applies only to the StarBright ABA website. It does not govern the practices of third-party services, such as Simple Practice or JotForm, which we use to manage payments, collect document signatures, and gather sensitive information. Please review the privacy policies of these platforms for details on their data practices:
                <ul>
                  <li>
                  Simple Practice Privacy Policy: <a href="https://www.simplepractice.com/privacy/">https://www.simplepractice.com/privacy/</a>
                  </li>
                  <li>
                    JotForm Privacy Policy: <a href="https://www.jotform.com/privacy/">https://www.jotform.com/privacy/</a>
                  </li>
                </ul>
              </p>
            <h3>
              2. Information We Collect
            </h3>
              <p>
              StarBright ABA does not directly collect personal information through forms on our website. However, we utilize third-party services for the following purposes:
              </p>
              <h5>
                a.Intial Inquiries (via JotForm)
              </h5>
                <p>
                When you submit an inquiry through JotForm, the following information may be collected:
                <ul>
                    <li>Name</li>
                    <li>Email Address</li>
                    <li>Phone Number</li>
                    <li>Sensitive personal information, such as helath and insurance details</li>
                  </ul>
                </p>
                  
              <h5>
                b.Patient Portal (via Simple Practice)
              </h5>
                <p>
                For managing payments and collecting signed documents, we use the Simple Practice patient portal. Any personal or health-related information submitted through Simple Practice is governed by their privacy policy.
                </p>
              <h5>
                c. Website Usage Data
              </h5>
                <p>
                We may collect non-personally identifiable information through cookies and similar technologies to improve your website experience. This may include:
                  <ul>
                    <li>Your IP address</li>
                    <li>Browser type</li>
                    <li>Device type</li>
                    <li>Pages visited on our site</li>
                    <li>Time spent on our website</li>
                  </ul>
                </p>
            <h3>
              3. How We Use Your Information
            </h3>
              <p>
                We use the information collected to:
                  <ul>
                    <li>Respond to inquiries submitted through JotForm</li>
                    <li>Process payments and manage signed documents via Simple Practice</li>
                    <li>Improve website functionality and user experience</li>
                    <li>Comply with legal and regulatory obligations</li>
                  </ul>
              </p>
            <h3>
              4. How We Share Your Information
            </h3>
              <p>
                StarBright ABA does not sell or share your personal information with third parties for marketing purposes. We may share information as required to:
                  <ul>
                    <li>Comply with legal obligations</li>
                    <li>Protect our rights and safety</li>
                    <li>Facilitate communication and services through trusted third-party platforms (e.g., JotForm and Simple Practice)</li>
                  </ul>
              </p>
            <h3>
              5. Data Security
            </h3>
              <p>
                We implement reasonable security measures to protect your information. While no system can guarantee absolute security, we work diligently to safeguard your data against unauthorized access, disclosure, or misuse.
                <ul>
                  <li>
                    <b>JotForm:</b>Inquiry submissions are securely stored on their platform. For more details, review their security page at: <a href="https://www.jotform.com/security/">https://www.jotform.com/security/</a>
                  </li>
                  <li>
                    <b>Simple Practice:</b>Patient data is stored securely and is HIPAA-compliant. For details, visit their security page at: <a href="https://www.simplepractice.com/features/security/">https://www.simplepractice.com/features/security/</a>
                  </li>
                </ul>            
              </p>
            <h3>
              6. Your Rights
            </h3>
              <p>
                You have the following rights regarding your personal information:
                <ul>
                  <li><b>Access and Review: </b>You may request access to or correction of your personal information.</li>
                  <li><b>Data Deletion: </b>You may request deletion of your personal information where legally permissible.</li>
                  <li><b>Withdraw Consent: </b>You may withdraw consent for data processing where applicable.</li>
                </ul>
                For any inquiries regarding your data, please contact us at <b>info@starbrightaba.com</b>
              </p>
            <h3>
              7. Cookies and Tracking
            </h3>
              <p>
                We may use cookies and similar technologies to enhance your browsing experience. You can manage cookie preferences through your browser settings. Disabling cookies may impact website functionality.
              </p>
            <h3>
              8. Third-Party Links
            </h3>
              <p>
                Our website may contain links to third-party websites, such as Simple Practice and JotForm. We are not responsible for the privacy practices of these third parties and encourage you to review their privacy policies.
              </p>
            <h3>
              9. Changes to This Privacy Policy
            </h3>
              <p>
                We reserve the right to update this Privacy Policy at any time. Changes will be posted on this page with an updated "Effective Date." We encourage you to review this policy periodically.
              </p>
            <h3>
              10. Contact Us
            </h3>
              <p>
              If you have any questions about this Privacy Policy or how we handle your information, please contact us:
              </p>
              <p>
                <b>StarBright ABA, PLLC</b>
                <br />
                118 River Rd #14
                <br />
                Harriman, NY 10926
                <br />
                Phone: (845) 320-2855
                <br />
                Email: info@starbrightaba.com
              </p>
        </div>
       
      </Section>
    </>
  );
}
