import React from 'react';
import BannerSectionStyle5 from '../Section/BannerSection/BannerSectionStyle5';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';
import FaqSection from '../Section/FaqSection';

const faqData = [
    {
      title: 'What are the signs and symptoms of Autism?',
      content: (
        <>
          <p>
            If you’re noticing social communication, interaction skills, or behaviors that seem restricted and repetitive, you may have questions about Autism Spectrum Disorder (ASD). Early understanding and support can make a big difference, so we encourage you to reach out to your pediatrician if you feel your child may be showing any of these signs.
          </p>
          <br></br>
          <p>
          ASD can present differently depending on age and development. Below are resources that can help you better understand how ASD might look at various stages:
          </p>
          <br></br>
          <ul>
            <li>
              {' '}
              <a href="https://www.autismspeaks.org/signs-autism" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'semi-bold', textDecoration: 'underline' }}>
              Signs Of Autism in Children
              </a>
            </li>
            <li>
              {' '}
              <a href="https://www.autismspeaks.org/signs-autism-adults" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'semi-bold', textDecoration: 'underline' }}>
              Signs of Autism in Adults
              </a>
            </li>
            <li>
             {' '}
              <a href="https://www.autismspeaks.org/signs-autism-women" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'semi-bold', textDecoration: 'underline' }}>
              Signs of Autism in Women and Girls
              </a>
            </li>
          </ul>
          <br></br>
          <p>
          Remember, every individual with ASD is unique. Seeking guidance is a positive first step toward finding the right support and resources for your loved one.
          </p>
        </> 
      ),
    },
    {
      title: 'My child just received an Autism Spectrum Disorder diagnosis.  Where can I access information about ASD and treatment options?',
      content: (
        <>
          <p>Receiving an Autism Spectrum Disorder (ASD) diagnosis can be overwhelming, but you’re not alone. There are many resources available to help you understand ASD, explore treatment options, and find support. Here are some trusted resources to guide you:</p>
          <br></br>
          <ol>
            <li><b>ASAT New Parent Packet</b>
              <br></br>
              This packet provides valuable information for parents who are new to ASD. It covers foundational information about autism and evidence-based treatments, with tips for navigating next steps.
              <br></br>
              <a href="https://asatonline.org/wp-content/uploads/2021/03/New-Parent-Packet-2021.pdf" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'semi-bold', textDecoration: 'underline' }}>
              Download the ASAT New Parent Packet        
              </a>
            </li>
            <br></br>
            <li><b>Autism Speaks Newly Diagnosed Autism Toolkit</b>
              <br></br>
              Autism Speaks offers a toolkit for families who have recently received a diagnosis. It includes insights on what to expect, coping strategies, and how to get started with support services.
              <br></br>
              <a href="https://www.autismspeaks.org/new-autism-diagnosis" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'semi-bold', textDecoration: 'underline' }}>
              Access the Autism Speaks Newly Diagnosed Toolkit
              </a>
            </li>
            <br></br>
            <li><b>Introduction to Applied Behavior Analysis (ABA)</b>
              <br></br>
              ABA is one of the most researched and widely used therapies for autism. These articles provide a comprehensive introduction to ABA, along with answers to common questions:
              <br></br>
              <ul>
                <li>
                  <a href="https://asatonline.org/wp-content/uploads/2021/03/New-Parent-Packet-2021.pdf" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'semi-bold', textDecoration: 'underline' }}>
                  Questions and Answers About ABA
                  </a>
                </li>
                <li>
                  <a href="https://www.autismspeaks.org/aba-and-other-therapies" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'semi-bold', textDecoration: 'underline' }}>
                  ABA and Other Therapies
                  </a>
                </li>
                <li>
                  <a href="https://asatonline.org/for-parents/learn-more-about-specific-treatments/applied-behavior-analysis-aba/" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'semi-bold', textDecoration: 'underline' }}>
                  ASAT Overview on ABA
                  </a>
                </li>
              </ul>              
            </li>
            <br></br>
            <li><b>Learn More About Specific Treatments</b>
              <br />
              For an overview of a range of autism treatments, the Association for Science in Autism Treatment (ASAT) provides helpful resources to explore different evidence-based options:
              <br />
              <a href="https://asatonline.org/for-parents/learn-more-about-specific-treatments/" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'semi-bold', textDecoration: 'underline' }}>
              Explore Treatment Options on ASAT
              </a>
            </li>
          </ol>
          <p>
          Taking the time to review these resources can empower you to make informed decisions and create a support plan tailored to your child’s needs. Remember, there are many caring professionals and families who have walked this path and are here to support you.
          </p>
        </>
      ),
    },
    {
      title: 'What is ABA? ',
      content: (
        <p>
          Applied Behavior Analysis (ABA) is a therapy that helps children with autism learn important skills like communication, play, and social interaction. It focuses on understanding how your child responds to their environment and uses positive reinforcement to encourage helpful behaviors. ABA is tailored to your child’s unique needs to ensure steady progress in areas that matter most to them. It is considered the gold standard in autism treatment, given there is over 60 years of research showing its effectiveness in helping children of all ages improve and thrive in various settings, such as home, school, and the community.
        </p>
      )
    },
    {
      title: 'What does ABA therapy focus on? ',
      content: (
        <p>
        ABA therapy focuses on a variety of essential skills to support your child’s development and independence. Programs can focus on skills like toileting, sleeping, language use, play, and social interactions, all tailored to your child’s needs. A primary focus of ABA therapy is to increase these positive skills while also addressing and reducing any behaviors that might interfere with your child’s ability to succeed across different environments. ABA therapy helps children build the skills they need to thrive at home, in school, and in the community.
        </p>
      )
    },
    {
      title: 'How many hours are usually recommended? ',
      content: (
        <>
          <p>
          The number of recommended ABA therapy hours varies depending on your child’s unique needs. A board-certified behavior analyst (BCBA) will conduct a thorough evaluation to determine the ideal level of support. StarBright offers a range of options, including focused programs with fewer hours for specific skill-building and full-day comprehensive programs covering all developmental areas.
          </p>
          <br />
          <p>
          After your child’s intake evaluation, we’ll review the recommended hours with you and tailor a plan that best supports their growth and development.
          </p>        
        </>
       
      )
    },
    {
      title: 'What is needed to get started with ABA therapy services?  ',
      content: (
        <>
          <p>
            Starting ABA therapy involves a few key steps:
          </p>
          <br />
          <ol>
            <li><b>Intake Process and Benefits Check</b>
              <br />
              We’ll begin with an intake process to gather information about your child’s needs and verify insurance benefits or eligibility for ABA services.
            </li>
            <br />
            <li><b>Referral for ABA Services</b>
              <br />
              A referral from your child’s diagnosing or treating pediatrician is typically required by insurance to start ABA therapy.
            </li>
            <br />
            <li><b>Comprehensive Evaluation</b>
              <br />
              A full evaluation, often including assessments like the Autism Diagnostic Observation Schedule (ADOS) or Childhood Autism Rating Scale (CARS), helps us design a personalized therapy plan.
            </li>
            <br />
            <li><b>Comprehensive Physical</b>
              <br />
              Your child will need a recent physical from their pediatrician to ensure all medical factors are considered.
            </li>
          </ol>
          <p>
          Once these steps are complete, we’ll work with you to create a customized ABA therapy plan tailored to your child’s unique needs. Our team is here to help you each step of the way!
          </p>
        </>
      )
    }, 
    {
      title: 'What can I do if I can’t get an appointment for a diagnosis?',
      content: (
        <>
        <p>
          If you're struggling to secure an appointment for a comprehensive evaluation, try reaching out to multiple providers to check their availability. Being flexible with your schedule or requesting appointments during less busy times or requesting to be called for last minute cancellations can also help. 
        </p>
        <br />
        <p>
          As an alternative to an in person evaluation, consider utilizing a telehealth evaluation appointment service such as  <a href="https://www.asyouare.com" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'semi-bold', textDecoration: 'underline' }}>
              www.asyouare.com
              </a>. Gathering relevant documentation about your child's developmental history can also be useful when you do get an appointment. Stay proactive in seeking support, and consider asking your pediatrician or other parents for recommendations.
          
        </p>
        
      </>
      )
    },
    {
      title: 'What do I do if my insurance doesn’t approve the fully recommended treatment hours or location recommended by the BCBA?',
      content: (
        <>
          <p>
          If your insurance does not approve the full treatment hours or the location recommended by your board-certified behavior analyst (BCBA), there are several steps you can take: 
          </p>
          <ol>
            <li><b>Contact Your Insurance Company:</b> Reach out to your insurance provider’s customer service department for clarification on why the treatment hours or location were not approved. Ask about the appeals process and gather information on any necessary documentation.
            </li>
            <li><b>Request an Appeal:</b>  ABA therapy  treatment is a medically necessary treatment and you can file an appeal with your insurance company. This may involve submitting additional documentation from your BCBA that outlines the importance of the recommended hours or location or obtaining additional information from the referring or diagnosing provider.
            </li>
            <li><b>Explore Other Funding Options:</b> If your insurance continues to deny the recommended treatment, consider exploring other funding sources, such as state-funded programs, grants, or community resources that may assist with therapy costs.
            </li>
            <li><b>Stay Informed:</b>  Resources like the Autism Society and Autism Speaks offer helpful information about insurance coverage and advocacy. Here are some links to guide you:
              <br />
              <ul>
                <li>
                  <a href="https://www.autismspeaks.org/financial-autism-support" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'semi-bold', textDecoration: 'underline' }}>
                Autism Society:  Financial Assistance Resources
                  </a>
                </li>
                <li>
                  <a href="https://www.autismspeaks.org/health-insurance" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'semi-bold', textDecoration: 'underline' }}>
                  Autism Speaks: Navigating Insurance
                  </a>
                </li>
              </ul>
            </li>
          </ol>
          <p>
          Navigating insurance approvals can be challenging, but staying proactive and informed can help you advocate for the best possible care for your child. We are here to help guide you through this process if needed. 
          </p>
        </>
      )
    },
    {
      title: 'What should I look for in an ABA program?',
      content: (
        <>
          <p>
          When selecting an ABA therapy program, there are several key factors to consider to ensure you choose the right fit for your child. By considering these factors, you can make an informed decision when choosing an ABA organization that will best support your child's growth and development:
          </p>
          <ol>
            <li><b>Individualized Programming:</b>Look for organizations that offer personalized treatment plans tailored to your child’s unique needs and goals. This approach ensures that your child receives the most effective therapy.
            </li>
            <li><b>Timely Intake:</b>A smooth and efficient intake process is important for getting started with services quickly. This allows your child to begin therapy without unnecessary delays.
            </li>
            <li><b>Consistent Staffing:</b>Consistency in staffing is crucial for continuity of care. Familiarity with staff helps your child feel comfortable and supported throughout their therapy journey.
            </li>
            <li><b>Oversight by BCBAs:</b>A high level of oversight by Board Certified Behavior Analysts (BCBAs) is essential. BCBAs are certified experts who ensure that treatment is based on evidence-based practices. They develop the treatment plans and monitor progress to ensure effectiveness.
            </li>
            <li><b>Therapy delivered by Registered Behavior Technicians (RBTs):</b>At StarBright, our RBTs play a vital role in providing ABA therapy to your child. RBTs receive specialized training to implement the treatment plans designed by BCBAs, ensuring high-quality, hands-on support. While BCBAs focus on creating and supervising the treatment plans, RBTs concentrate on your child's daily progress, providing personalized and engaging sessions to help them thrive.
            </li>
          </ol>
        </>
        
      )
    },
    {
      title: 'What makes StarBright different from other providers? ',
      content: (
        <p>
         What sets StarBright apart from other ABA providers is our personalized approach to treatment. Our dedicated team takes the time to get to know your child well, ensuring that you interact with the same caring staff from intake through discharge. This continuity of care fosters strong relationships and trust, which are essential for effective treatment. We prioritize staying up-to-date with the latest research and utilize only evidence-based practices, guaranteeing that your child receives top-quality care. In addition, we offer frequent caregiver meetings to keep you involved and supported throughout your child’s journey. This collaborative approach not only empowers you as a caregiver but also helps reinforce your child’s progress at home. At StarBright, we are committed to providing an engaging and nurturing environment that truly focuses on your child's individual needs.
        </p>
      )
    },
    {
      title: 'What is the difference between ABA with StarBright and Early Intervention Services? ',
      content: (
        <>
          <p>
            ABA therapy differs from early intervention services in both frequency and intensity. While early intervention often offers limited sessions, which may not adequately address all of your child's needs, StarBright provides a comprehensive program with more frequent and intensive sessions. This approach allows for greater progress, as research indicates that a higher frequency of therapy hours can lead to better outcomes in ABA.
          </p>
          <br/>
          <p>
            At StarBright, we operate under a medical model that is data-driven and overseen by Board Certified Behavior Analysts (BCBAs) or Licensed Behavior Analysts (LBAs). Our Registered Behavior Technicians (RBTs) implement the strategies outlined by the BCBA, ensuring consistent and effective care. Additionally, unlike many early intervention programs, children at StarBright do not age out at 3, allowing them to continue receiving support as they grow and their needs evolve. This comprehensive and continuous care is vital for helping children achieve their full potential.
          </p>
          
        </>
      )
    },
    {
      title: 'Is a special education program going to address my child’s needs? ',
      content: (
        <>
          <p>While special education services can play a crucial role in supporting children with ASD, they may not fully address their comprehensive needs. One reason is the intensity of services required for effective treatment, which is often difficult to achieve in a group setting. Individualized ABA therapy provides focused support that targets specific behaviors and skills.</p>
          <br />
          <p>BCBA providers have the training and expertise needed to implement effective strategies, addressing both medical and behavioral needs. Additionally, ABA therapy involves a higher level of caregiver involvement, equipping families with strategies to reinforce progress at home.</p>
          <p>Finally, ABA emphasizes individualized instruction while still allowing children to learn alongside their peers in a clinic setting, fostering essential social skills. </p>
        </>
      )
    },
    {
      title: 'How can I support my child’s progress at home? ',
      content: (
        <p>
         You can effectively support your child's ABA therapy at home by collaborating with their BCBA to help set and achieve important goals for your child. Regular meetings with your BCBA are essential for discussing progress and developing strategies to reinforce skills learned in therapy. Encourage your child to practice these skills in everyday situations, as consistency helps them become part of their routine. The BCBA will provide regular updates on your child’s progress, sharing successes and any challenges they may face. By actively engaging in this process, you create a supportive environment that fosters your child's growth and success.
        </p>
      )
    },
      
  ];

export default function FaqPage() {
  pageTitle('FAQ');
  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/departments/banner_bg.png"
        imgUrl="/images/faq/child-with-question-mark-sticky.jpg"
        title="FAQ"
        subTitle="Frequently Asked Questions <br /><br /> Have a different question?<br />We'd love to answer! Give us a call at 845-320-2855"
      />
     
     {/*Start FAQ Section */}
      <Section topMd={190} topLg={145} topXl={105} bottomMd={190} bottomLg={105} bottomXl={105}>
        <FaqSection
          data={faqData}
          sectionTitle="Frequently Asked Questions"
          sectionTitleUp="FAQ"
        />
      </Section>
      {/* End FAQ Section */}
    </>
  );
}