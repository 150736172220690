import { Icon } from '@iconify/react';
import React from 'react';

export default function ContactInfoWidget() {
  return (
    <ul className="cs_contact_widget">
      <li>
        <i className="cs_accent_bg">
          <Icon icon="ep:location" />
        </i>
        118 River Rd # 14, Harriman, NY 10926
      </li>
      <li>
        <i className="cs_accent_bg">
          <Icon icon="fluent:call-24-regular" />
        </i>
        Phone | (845) 320-2855
      </li>
      <li>
        <i className="cs_accent_bg">
          <Icon icon="cil:fax" />
        </i>
        Fax | (845) 395-9296
      </li>
    </ul>
  );
}
