// import { Icon } from '@iconify/react';
// import React from 'react';
// import { Link } from 'react-router-dom';

// export default function Team({
//   imgUrl,
//   name,
//   designation,
//   description,
//   social,
// }) {
//   return (
//     <div className="cs_team cs_style_1 text-center cs_radius_20 cs_type_1">
//       <div className="cs_member_img">
//         <img src={imgUrl} alt={name} />
//       </div>
//       <div className="cs_team_meta">
//         <div>
//           <h3 className="cs_member_name cs_semibold cs_fs_40">{name}</h3>
//           <p className="cs_member_designation cs_fs_20 cs_heading_color">
//             {designation}
//           </p>
//           <p className="cs_member_description">{description}</p>
//         </div>
//         <div>
//           <div className="cs_social_links">
//             {social?.map((item, index) => (
//               <Link to={item.href} key={index}>
//                 <Icon icon={item.icon} />
//               </Link>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

import React, { useState } from 'react';

export default function Team({ imgUrl, name, designation, credentials, background, interests }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="cs_team cs_style_1 text-center cs_radius_20 cs_type_1">
      {/* Image Section */}
      <div className="cs_member_img">
        <img
          src={imgUrl}
          alt={name}
          style={{
            borderRadius: '20px',
          }}
        />
      </div>

      {/* Metadata Section */}
      <div className="cs_team_meta">
        <h3 className="cs_member_name cs_semibold cs_fs_40">{name}</h3>
        <p
          className="cs_member_credentials"
          style={{
            marginTop: '5px',
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#555',
            textTransform: 'uppercase',
            minHeight: '20px',
          }}
        >
          {credentials || '\u00A0'}
        </p>
        <p className="cs_member_designation cs_fs_20 cs_heading_color">
          {designation}
        </p>

        {/* Expansion Button */}
        <button
          className="cs_expand_button"
          onClick={toggleAccordion}
          style={{
            marginTop: '15px',
            padding: '10px 20px',
            border: 'none',
            backgroundColor: '#307BC4',
            color: '#fff',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          {isExpanded ? 'Close Details' : 'Learn More'}
        </button>

        {/* Accordion Section */}
        {isExpanded && (
          <div className="cs_accordion" style={{ marginTop: '20px', textAlign: 'left' }}>
            {background && (
              <div className="cs_accordion_item">
                <h4 className="cs_accordion_title">Background</h4>
                {Array.isArray(background)
                  ? background.map((paragraph, index) => (
                      <p key={index} className="cs_accordion_content">
                        {paragraph}
                      </p>
                    ))
                  : null}
              </div>
            )}
            {interests && (
              <div className="cs_accordion_item">
                <h4 className="cs_accordion_title">Interests</h4>
                <p className="cs_accordion_content">{interests}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
