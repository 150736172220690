import React, { useState } from 'react';
import BrandsCarousel from '../../Slider/BrandsCarousel';
import Spacing from '../../Spacing';

export default function BrandsSectionStyle2({ data, sectionTitle, href, btnText }) {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleLinkClick = (e) => {
    e.preventDefault(); // Prevents navigation
    setIsPopupVisible(true); // Show the popup
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false); // Hide the popup
  };

  return (
    <div className="container">
      {sectionTitle && (
        <>
          <h2 className="cs_fs_48 text-center mb-0 cs_semibold">
            {sectionTitle}
          </h2>
          {btnText && (
            <div className="cs_heading_color cs_medium btn_wrapper">
              <a href={href} className="cs_text_btn" onClick={handleLinkClick}>
                {btnText}
              </a>
            </div>
          )}
          <Spacing md="40" />
        </>
      )}
      
      <BrandsCarousel data={data} />

      {/* Popup Component */}
      {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button className="popup-close" onClick={handleClosePopup}>
              &times;
            </button>
            <h3>Don't See Your Insurance?</h3>
            <p>Give us a call at (845)320-2855.<br /> We may still be able to help you get your services covered!</p>
          </div>
        </div>
      )}
    </div>
  );
}
