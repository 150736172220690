// import React from 'react';
// import Button from '../Button';

// export default function Cta({ title, subTitle, bgUrl, btnUrl, btnText, featuredImg, imgCaption }) {
//   return (
//     <div className="container">
//       <div
//         className="cs_cta cs_style_1 cs_bg_filed cs_radius_20"
//         style={{ backgroundImage: `url(${bgUrl})`, display: 'flex', alignItems: 'center', gap: '20px' }}
//       >
//         {/* Featured Image Section */}
//         <div className="cs_featured_img" style={{ flex: '1', textAlign: 'center' }}>
//           <img
//             src={featuredImg}
//             alt={imgCaption || 'Featured'}
//             style={{ width: '100%', borderRadius: '10px', objectFit: 'cover' }}
//           />
//           {imgCaption && (
//             <p
//               className="cs_img_caption"
//               style={{ marginTop: '10px', fontSize: '14px', color: '#555' }}
//             >
//               {imgCaption}
//             </p>
//           )}
//         </div>

//         {/* Text and Button Section */}
//         <div className="cs_cta_content" style={{ flex: '2' }}>
//           <h2 className="cs_cta_title cs_fs_72">{title}</h2>
//           <p className="cs_cta_subtitle cs_heading_color">{subTitle}</p>
//           <Button btnUrl={btnUrl} btnText={btnText} />
//         </div>
//       </div>
//     </div>
//   );
// }

import React from 'react';
import Button from '../Button';

export default function Cta({ title, subTitle, bgUrl, btnUrl, btnText, featuredImg, imgCaption }) {
  return (
    <div
      className="cs_cta cs_style_1 cs_bg_filed cs_radius_20"
      style={{
        backgroundImage: `url(${bgUrl})`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px',
      }}
    >
      {/* Left: Featured Image Section */}
      {featuredImg && (
        <div
          className="cs_featured_img"
          style={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={featuredImg}
            alt={imgCaption || 'Featured'}
            style={{
              width: '100%',
              maxWidth: '90%', // Limit the image width
              borderRadius: '10px',
              objectFit: 'cover',
            }}
          />
          {imgCaption && (
            <p
              className="cs_img_caption"
              style={{
                marginTop: '10px',
                fontSize: '14px',
                color: '#555',
                textAlign: 'center',
              }}
            >
              {imgCaption}
            </p>
          )}
        </div>
      )}

      {/* Right: Title, Subtitle, and Button Section */}
      <div
        className="cs_cta_content"
        style={{
          flex: '1',
          paddingLeft: '20px',
          textAlign: 'left',
        }}
      >
        <h2 className="cs_cta_title cs_fs_72">{title}</h2>
        <p className="cs_cta_subtitle cs_heading_color">{subTitle}</p>
        <Button btnUrl={btnUrl} btnText={btnText} />
      </div>
    </div>
  );
}

