import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation for detecting route changes
import BannerSectionStyle5 from '../Section/BannerSection/BannerSectionStyle5';
import Section from '../Section';
import ContactInfoSection from '../Section/ContactInfoSection';
import { pageTitle } from '../../helpers/PageTitle';

export default function Contact() {
  const location = useLocation(); // Get current location

  useEffect(() => {
    // Scroll to the top whenever the component mounts or the location changes
    window.scrollTo(0, 0);
  }, [location]); // Depend on location changes

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://form.jotform.com/jsform/243444628209156';
    script.async = true;

    const formContainer = document.getElementById('jotform-container');
    if (formContainer) {
      formContainer.appendChild(script);
    }

    return () => {
      if (formContainer) {
        formContainer.removeChild(script);
      }
    };
  }, []);

  pageTitle('Contact');

  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/departments/banner_bg.svg"
        imgUrl="/images/contact/smiling-businesswoman-on-the-phone-in-office-2024-09-22-11-22-24-utc.jpg"
        title="Contact Us"
        subTitle="Ready to start the enrollment process or just have a question? <br /><br />Fill out our Inquiry Form below!"
      />
       <div id="jotform-container" style={{ marginTop: '0px', textAlign: 'center' }}></div>
      <Section
        topMd={200}
        topLg={150}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <ContactInfoSection sectionTitle="Find Us Here" />
      </Section>

    </>
  );
}
