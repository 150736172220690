import React from 'react';
import BannerSectionStyle5 from '../Section/BannerSection/BannerSectionStyle5';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';

export default function Terms() {
  pageTitle('Terms & Conditions');
  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/departments/banner_bg.svg"
        imgUrl="/images/terms.jpg"
        title="Terms <br /> and Conditions"
        subTitle=""
      />
    
      <Section
        topMd={200}
        topLg={150}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
      
        <div className="container">
            <h5>
            Effective Date: 12-05-2024
            </h5>
            <p>
                Welcome to the StarBright ABA, PLLC website (www.starbrightaba.com). By accessing or using this website, you agree to comply with and be bound by these Terms and Conditions. Please read them carefully.
            </p>
            <h3>
                1. Acceptance of Terms
            </h3>
              <p>
              Your use of this website constitutes your agreement to these Terms and Conditions. If you do not agree with any part of these terms, please do not use our website.
              </p>
            <h3>
                2. Use of Third-Party Services
            </h3>
              <p>
                StarBright ABA uses the following third-party platforms to manage inquiries, payments, and document signatures:
                <ul>
                    <li><b>JotForm: </b>For collecting personally identifiable information (PII), including sensitive personal, health, and insurance details.</li>
                    <li><b>Simple Practice: </b>For managing payments and collecting document signatures through our secure patient portal.</li>
                </ul>
              </p>
              <p>
                These platforms are HIPAA-compliant, ensuring the secure handling of your information. By using our services, you agree to comply with their respective terms:
                <ul>
                    <li><b>JotForms Terms of Service: </b><a href="https://www.jotform.com/terms/">https://www.jotform.com/terms/</a></li>
                    <li><b>Simple Practice Terms of Service: </b><a href="https://www.simplepractice.com/terms/">https://www.simplepractice.com/terms/</a></li>
                </ul>
              </p>
              
            <h3>
              3.Data Collection on This Website
            </h3>
              <p>
              The StarBright ABA website itself does not host forms or directly collect PII. All sensitive information must be submitted exclusively through JotForm or Simple Practice. Users are encouraged not to submit any PII through general inquiries or contact methods on this website.
              </p>
            <h3>
              4. Website Content
            </h3>
              <p>
              All content on this website, including text, graphics, logos, images, and other materials, is the property of StarBright ABA or its content providers and is protected by copyright, trademark, and other intellectual property laws.
              </p>
              <p>
                You may not:
                  <ul>
                    <li>Copy, reproduce, distribute, or use the content for commercial purposes without prior written permission.</li>
                    <li>Misuse or interfere with the operation of the website.</li>
                  </ul>
              </p>
            <h3>
              5. Privacy Policy
            </h3>
              <p>
              Your use of this website is also governed by our Privacy Policy, which explains how we collect, use, and protect your information. Please review our Privacy Policy at: <a href="/privacy">www.starbrightaba.com/privacy</a>
              </p>
            <h3>
              6. HIPAA Compliance
            </h3>
              <p>
              StarBright ABA is committed to ensuring the privacy and security of Protected Health Information (PHI). Sensitive information, including PII and health details, must only be submitted through the designated HIPAA-compliant platforms:
                <ul>
                  <li><b>JotForm: </b>For initial inquiries and sensitive details.</li>
                  <li><b>Simple Practice: </b>For payment management and document signatures.</li>
                </ul>
              </p>
            <h3>
              7. Third-Party Links
            </h3>
              <p>
                Our website may contain links to third-party websites, such as JotForm and Simple Practice. These links are provided for your convenience and do not signify our endorsement of such websites. We are not responsible for the content or privacy practices of third-party websites.
              </p>
            <h3>
              8. Disclaimers
            </h3>
              <p>
                While we strive to ensure the accuracy of the information on this website, we make no representations or warranties about its completeness, reliability, or suitability for any purpose. Your use of the website is at your own risk.
              </p>
            <h3>
              9. Limitation of Liability
            </h3>
              <p>
                To the fullest extent permitted by law, StarBright ABA, PLLC shall not be liable for any damages, including direct, indirect, incidental, or consequential damages, arising from your use of this website or third-party services.
              </p>
            <h3>
              10. Indemnification
            </h3>
              <p>
              You agree to indemnify and hold harmless StarBright ABA, PLLC, its employees, and affiliates from any claims, liabilities, damages, and expenses, including legal fees, arising from your use of this website or violation of these Terms and Conditions.
              </p>
            <h3>
                11. Governing Law
            </h3>
                <p>
                    These Terms and Conditions are governed by and construed in accordance with the laws of Orange County, New York.
                </p>
            <h3>
                12. Modification of Terms
            </h3>
                <p>
                    We reserve the right to update these Terms and Conditions at any time. Changes will be posted on this page with an updated "Effective Date." Your continued use of the website after changes have been posted constitutes acceptance of the updated terms.
                </p>
            <h3>
                13. Contact Us
            </h3>
                <p>
                    If you have any questions about these Terms and Conditions, please contact us:
                </p>
                <p>
                    <b>StarBright ABA, PLLC</b>
                    <br />
                    118 River Rd #14
                    <br />
                    Harriman, NY 10926
                    <br />
                    Phone: (845) 320-2855
                    <br />
                    Email: info@starbrightaba.com
                </p>
        </div>
       
      </Section>
    </>
  );
}
